import React, { useEffect, useState } from 'react';
import { Text, MenuIcon, ProviderConsumer, ThemePrepared, TeamCreateIcon, Tooltip } from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppStateContext, useSetState, appState } from '../../AppState';
import CategoryIcon from '../../../svg/category.svg';
import TicketTypeIcon from '../../../svg/ticket-type.svg';
import LifecycleIcon from '../../../svg/lifecycle-icon-settings-nav.svg';
import PriorityIcon from '../../../svg/priority-icon.svg';
import StatusIcon from '../../../svg/status.svg';
import AutomationIcon from '../../../svg/automation.svg';
import SLAIcon from '../../../svg/SLA-icon.svg';
import OpenAiIconLight from '../../../svg/fileTypes/light/openai.svg';
import OpenAiIconDark from '../../../svg/fileTypes/dark/openai.svg';
import { ThemeColorScheme } from '../../../components/shared/common/TeamsTheme';

import { ColorIcon, FeedbackIcon, PaymentCardIcon } from '@fluentui/react-icons-mdl2';
import {
  SETTINGS_PAGES,
  SIDENAV_ARRANGED_KEYS,
  ARRANGED_SETTING_KEYS_TEAMS,
  ENDPOINT_SECURITY_MAPPINGS,
  LICENSE_FEATURE,
  SIDENAV_AGENTS_KEYS,
  ADMINISTRATORS,
  ANALYSTS,
  AGENTS,
  KNOWLEDGEAGENT,
  SIDENAV_KBAGENTS_KEYS,
  ARRANGED_AGENT_SETTING_KEYS,
  ARRANGED_KBAGENT_SETTING_KEYS,
  ARRANGED_AGENTKB_SETTING_KEYS
} from '../../shared/utils/constants';
import { checkInTeams } from '../../App';
import eventBus from '../../shared/services/eventBus.service';
import { themeNames } from '@fluentui/react-teams';
import { mergeStyleSets } from '@fluentui/react';
import { getViewIdFromStorage } from '../../tikit/toolbar/CustomViewPopup/heplers/customPopupHelper';
import { Route } from '../../../route-constants';
import { MyGroupWorkIcon } from '../../../svg';
import {i18n} from '../../shared/utils/helper';
import { getCachedFeature } from '../../shared/cache/FeatureCache';
import { GetArrangeSettingsKey } from '../../shared/common/CommonHelper';
import { CheckFeature } from '../../shared/components/CheckFeature';

const { t } = i18n();

const classNames = mergeStyleSets({
  sideNavCollapsed: {
    width: '48px !important',
    minWidth: '48px !important'
  },
  sideNavActive: { 
    backgroundColor: 'var(--mgt-theme-brand-background1)'
  },
  sideNameIcon: {
    fill: 'var(--mgt-theme-foregroundFocus)',
    marginRight: '-4px',
  },
  sideNav: {
    height: '100vh',
    width: '221px',
    minWidth: '221px',
    maxWidth: '221px',
    transition: '0.2s width ease-in-out',
    borderRight: '1px solid var(--mgt-theme-border-color)'
  }
});

interface Props {
  settingsPage?: string;
  hideSettingsNav?: boolean;
  active: string;
}

export interface SideNavProps {
  key: string;
  path: string;
  teamsPath?: string;
  iconElement: JSX.Element;
  title: string;
  newtab?: boolean;
  external?: boolean;
  group: string;
}

export const sidenav_unarranged: SideNavProps[] = [
  {
    key: 'tickets',
    path: '/',
    iconElement: <Icon iconName="Ticket" className={`text-lg`} />,
    title: t('side-nav.ticket'),
    group: 'main'
  },
  { 
    key: 'mywork',
    path: Route.myWork.pagePath,
    iconElement: <Icon iconName="WorkItem" className={`text-lg`} />,
    title: t('side-nav.my-work'),
    group: 'main'
  },
  {
    key: 'mygroupwork',
    path: Route.myGroupWork.pagePath,
    iconElement: <MyGroupWorkIcon className={classNames.sideNameIcon} />,
    title: t('side-nav.my-group-work'),
    group: 'main'
  },
  {
    key: SETTINGS_PAGES.USERS,
    path: `/${SETTINGS_PAGES.USERS}`,
    teamsPath: `/settings/${SETTINGS_PAGES.USERS}`,
    iconElement: <Icon iconName="ContactList" className={`text-lg`} />,
    title: t('side-nav.users'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.KNOWLEDGE,
    path: `/${SETTINGS_PAGES.KNOWLEDGE}`,
    teamsPath: `/settings/${SETTINGS_PAGES.KNOWLEDGE}`,
    iconElement: <Icon iconName="KnowledgeArticle" className={`text-lg`} />,
    title: t('side-nav.knowledge'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.SECURITY,
    path: `/settings/${SETTINGS_PAGES.SECURITY}`,
    iconElement: <Icon iconName="SecurityGroup" className={`text-lg`} />,
    title: t('side-nav.security'),
    group: 'security'
  },
  {
    key: SETTINGS_PAGES.ACCESS_TOKEN,
    path: `/settings/${SETTINGS_PAGES.ACCESS_TOKEN}`,
    iconElement: <Icon iconName="Signin" className={`text-lg`} />,
    title: t('side-nav.access-token'),
    group: 'security'
  },
  {
    key: SETTINGS_PAGES.GROUPS,
    path: `/settings/${SETTINGS_PAGES.GROUPS}`,
    iconElement: <Icon iconName="Group" className={`text-lg`} />,
    title: t('side-nav.groups'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.TAGS,
    path: `/settings/${SETTINGS_PAGES.TAGS}`,
    iconElement: <Icon iconName="Tag" className={`text-lg`} />,
    title: t('side-nav.tags'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.NOTIFICATION_CENTER,
    path: `/settings/${SETTINGS_PAGES.NOTIFICATION_CENTER}`,
    iconElement: <Icon iconName="PublicEmail" className={`text-lg`} />,
    title: t('side-nav.notification-center'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.EMAIL_CONNECTOR,
    path: `/settings/${SETTINGS_PAGES.EMAIL_CONNECTOR}`,
    iconElement: <Icon iconName="PublicEmail" className={`text-lg`} />,
    title: t('side-nav.email-connector'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.FEATURE_CONFIGURATION,
    path: `/settings/${SETTINGS_PAGES.FEATURE_CONFIGURATION}`,
    iconElement: <Icon iconName="Shield" className={`text-lg`} />,
    title: t('side-nav.consent-management'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.BOT_CONFIGURATION,
    path: `/settings/${SETTINGS_PAGES.BOT_CONFIGURATION}`,
    iconElement: <Icon iconName="TeamsLogoInverse" className={`text-lg`} />,
    title: t('side-nav.bot-configuration'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.AI_CONFIG,
    path: `/settings/${SETTINGS_PAGES.AI_CONFIG}`,
    iconElement: (
      <AppStateContext.Consumer>
       {(state) => {
        return state.theme === themeNames.Default ? (
            <OpenAiIconLight height="20" width="20" className="text-lg fill-current text-black" />
          ) : (
            <OpenAiIconDark height="20" width="20" className="text-lg fill-current text-white" />
          ) ;
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.ai-configuration'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.BILLING,
    path: `/settings/${SETTINGS_PAGES.BILLING}`,
    iconElement: <PaymentCardIcon className={`text-lg`} />,
    title: t('side-nav.billing'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.TEMPLATES,
    path: `/settings/${SETTINGS_PAGES.TEMPLATES}`,
    iconElement: <Icon iconName="FileTemplate" className={`text-lg`} />,
    title: t('side-nav.templates'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.PREFERENCES,
    path: `/settings/${SETTINGS_PAGES.PREFERENCES}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <ColorIcon
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.preferences'),
    group: 'settings'
  },
  {
    key: SETTINGS_PAGES.AUTOMATION,
    path: `/settings/${SETTINGS_PAGES.AUTOMATION}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <AutomationIcon
              height="12"
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.automation'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.STATUS,
    path: `/settings/${SETTINGS_PAGES.STATUS}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <StatusIcon
              height="14"
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.status'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.PRIORITY,
    path: `/settings/${SETTINGS_PAGES.PRIORITY}`,
    iconElement: (
      <PriorityIcon
        width={12}
        height={12}
        className={`text-lg`}
        style={{ margin: '0 3px' }}
      />
    ),
    title: t('side-nav.priority'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.CATEGORY,
    path: `/settings/${SETTINGS_PAGES.CATEGORY}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <CategoryIcon
              height="12"
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.category'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.TICKET_TYPES,
    path: `/settings/${SETTINGS_PAGES.TICKET_TYPES}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <TicketTypeIcon
              height="16"
              width="16"
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.ticket-types'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.LIFECYCLE,
    path: `/settings/${SETTINGS_PAGES.LIFECYCLE}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <LifecycleIcon
              height="16"
              width="16"
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.lifecycle'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.SLA,
    path: `/settings/${SETTINGS_PAGES.SLA}`,
    iconElement: (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <SLAIcon
              height="14"
              className={`text-lg fill-current ${state.theme === themeNames.Default ? "text-black" : "text-white"}`}
              style={{ margin: '7px 3px', }}
            />
          );
        }}
      </AppStateContext.Consumer>
    ),
    title: t('side-nav.sla'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.TEAMS,
    path: `/settings/${SETTINGS_PAGES.TEAMS}`,
    iconElement: (
      <TeamCreateIcon
        outline
        className={`text-lg`}
        style={{ margin: '0 2px' }}
      />
    ),
    title: t('side-nav.teams'),
    group: 'config'
  },
  {
    key: SETTINGS_PAGES.SURVEYS,
    path: `/settings/${SETTINGS_PAGES.SURVEYS}`,
    iconElement: (<FeedbackIcon className="text-lg" style={{ margin: '7px 0px' }} />),
    title: t('side-nav.surveys'),
    group: 'config'
  }
];
export const SideNav = (props: Props) => {
  let { settingsKey } = useParams<any>();
  const [sideNavItems, setSideNavItems] = useState<SideNavProps[]>([]);
  const [settingsArranged, setSettingsArranged] =  useState([]);
  const [settingsArrangedTeams, setSettingsArrangedTeams] =  useState([]);
  let setState = useSetState();
  const history = useHistory();
  let settingsKeyStart = 3;
  const currentState = appState();
  
  useEffect(() => {

    (async () => {
        const nc = await getCachedFeature(LICENSE_FEATURE.NotificationCenter);
        setSettingsArranged(getSettingsNavByRole(nc));
        setSettingsArrangedTeams(ARRANGED_SETTING_KEYS_TEAMS.map(
          key => sidenav_unarranged.filter(x => x.key === key)[0]
        ));
    

    const keyStart = sidenav_unarranged.findIndex(x => x.key === props.active);
    if (keyStart >= settingsKeyStart) {
      setState(state => {
        return {
          ...state,
          isSettingsSidenavCollapsed: false
        };
      });
    }
      setSideNavItems(await getSideNavByRole())
    })();

    const updateItems = () => {
      setState(state => { return { ...state }; });
    };
    eventBus.on('applicationRolesUpdated', updateItems);

    return () => {
      eventBus.remove('applicationRolesUpdated', updateItems)
    };
  }, []);


  const getSideNavByRole = async () => {
    let workItems: SideNavProps[];

    if(currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) || (currentState.userRoles.roles.includes(AGENTS) && currentState.userRoles.roles.includes(KNOWLEDGEAGENT)))
      workItems = SIDENAV_ARRANGED_KEYS.map(key => sidenav_unarranged.filter(x => x.key === key)[0]);
    else if (currentState.userRoles.roles.includes(AGENTS))
      workItems = SIDENAV_AGENTS_KEYS.map(key => sidenav_unarranged.filter(x => x.key === key)[0]);
    else if (currentState.userRoles.roles.includes(KNOWLEDGEAGENT))
      workItems = SIDENAV_KBAGENTS_KEYS.map(key => sidenav_unarranged.filter(x => x.key === key)[0]);
    
    const [enabled, groupEnabled] = await Promise.all([getCachedFeature(LICENSE_FEATURE.MyWork), getCachedFeature(LICENSE_FEATURE.MyGroupWork)]);
    if (!enabled) workItems = workItems.filter(i => i.key != 'mywork')
    if (!groupEnabled) workItems = workItems.filter(i => i.key != 'mygroupwork')
    
    return workItems;
  };
  

  const getSettingsNavByRole= (isNotificationCenter)=>{
    if(currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS))
      {
        return GetArrangeSettingsKey(isNotificationCenter).map(
          key => sidenav_unarranged.filter(x => x.key === key)[0]
        );
      }

    if(currentState.userRoles.roles.includes(AGENTS)){
      if (currentState.userRoles.roles.includes(KNOWLEDGEAGENT)){
        return ARRANGED_AGENTKB_SETTING_KEYS.map(
          key => sidenav_unarranged.filter(x => x.key === key)[0]
        );
      }else{
        return ARRANGED_AGENT_SETTING_KEYS.map(
          key => sidenav_unarranged.filter(x => x.key === key)[0]
        );
      }
    }

    if(currentState.userRoles.roles.includes(KNOWLEDGEAGENT))
      return ARRANGED_KBAGENT_SETTING_KEYS.map(
        key => sidenav_unarranged.filter(x => x.key === key)[0]
    );
  }

  const pageNavigateHandler = (key: string, path: string) => {
    // path is updated path and location.pathname is previous pathname
    if(path === location.pathname){
      return
    }

    let updatedpath = path;
    if(path === '/'){
      const lastTicketPage = localStorage.getItem('lastTicketPage') || 'table';
      const newPath = lastTicketPage === 'table' ? '/' : '/tickets/board'; // lastTicketPage can only be table or board
      const localCustomViewId = getViewIdFromStorage('custom');
      const queryParam = location.search && localCustomViewId ? `?id=${localCustomViewId}` : '';
      updatedpath = queryParam ? `${newPath}${queryParam}` : newPath;
    }
    history.push(updatedpath);
  }

  const renderSideNavItems = (isSidenavCollapsed?: boolean) => {
    const sidenav_arranged = sideNavItems || [];
    return sidenav_arranged.map((nav, i) => (
      <li key={nav.key} className={props.active === nav.key ? classNames.sideNavActive : ``}>
        <div onClick={() => pageNavigateHandler(nav.key, nav.path)}>
          <a className={`cursor-pointer px-4 py-2 block`}>
            <div className={`flex items-center`}>
              <Tooltip trigger={<span>{nav.iconElement}</span>} content={nav.title} />
              <Text
                content={nav.title}
                className={`pl-2 truncate ${isSidenavCollapsed ? 'hidden' : ''}`}
                weight={props.active === nav.key ? `semibold` : `regular`}
              />
            </div>
          </a>
        </div>
      </li>
    ));
  };

  const arrayToLink = (sidenavprops: SideNavProps[], globalTheme: ThemePrepared<any>) => {
    let prevGroup = null;
    return sidenavprops.map(nav => {
      const check = ENDPOINT_SECURITY_MAPPINGS.find(x => x.key == nav.key);
      if (check && JSON.parse(sessionStorage.getItem('my_application_roles') || '[]').filter(x => x.EndpointPath == check.path && x.Permissions.includes(check.method)).length <= 0)
        return null;

      const separator = (prevGroup !== null && prevGroup !== nav.group)
      prevGroup = nav.group;

      let returnLi = (<li key={nav.key} className={`${settingsKey === nav.key ? classNames.sideNavActive : ''} ${separator ? 'border-t' : ''}`} style={{
        borderLeft: settingsKey === nav.key ? `solid 3px ${globalTheme.siteVariables.colorScheme.default.foregroundActive}` : `solid 3px transparent`,
        borderTopColor: 'var(--mgt-theme-border-color)'
      }}>
        <Link to={checkInTeams() ? nav.teamsPath || nav.path : nav.path} className={`cursor-pointer px-4 py-2 block`}>
          <div className={`flex items-center`}>
            {nav.iconElement}
            <Text
              content={nav.title}
              className={`pl-2 truncate`}
              weight={settingsKey === nav.key ? `semibold` : `regular`}
            />
          </div>
        </Link>
      </li>
      );
      if (nav.key == SETTINGS_PAGES.AI_CONFIG) {
        return <CheckFeature featureName={LICENSE_FEATURE.ConversationalAi}>
                {returnLi}
              </CheckFeature>;
      }
      
      return returnLi;
    }).filter(x => x !== null);
  }

  const renderSettingSideNavItems = (globalTheme: ThemePrepared<any>) => {
    if (checkInTeams()) {
      return arrayToLink(settingsArrangedTeams, globalTheme);
    } else {
      return arrayToLink(settingsArranged, globalTheme);
    }
  };

  return (
    <AppStateContext.Consumer>
      {state => {
        return (
          <ProviderConsumer
            render={globalTheme => (
              <>
                {!checkInTeams() && <div style={{ textDecoration: 'none', ...ThemeColorScheme(globalTheme.siteVariables) }} className={`${classNames.sideNav} ${state.isSidenavCollapsed ? classNames.sideNavCollapsed : ''} browser-sidenav`}>
                  <ul>
                    <li className={'hidden md:block'}>
                      <a className={`cursor-pointer p-2 m-2 inline-block`} onClick={() => { setState(s => { return { ...s, isSidenavCollapsed: !s.isSidenavCollapsed }; }); }}>
                        <MenuIcon />
                      </a>
                    </li>
                    {renderSideNavItems(state.isSidenavCollapsed)}
                  </ul>
                </div>}
                {(!state.isLoading) && (
                <div className={`hidden ${(props.hideSettingsNav || typeof props.hideSettingsNav !== "undefined") ? '': 'md:block'} border-r`} style={{ width: '300px', ...ThemeColorScheme(globalTheme.siteVariables) }}>
                  <ul className="overflow-auto" style={{height:'90vh'}}>{renderSettingSideNavItems(globalTheme)}</ul>
                </div>
                )}
              </>
            )}
          />
        );
      }}
    </AppStateContext.Consumer>
  );
};

import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Flex,
  Menu,
  ProviderConsumer as FluentUIThemeConsumer,
  Button,
  Tooltip,
  ArrowLeftIcon
} from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import AppCSS from '../App.module.css';
import { checkInTeams } from '../App';
import { appState, useSetState } from '../AppState';
import { Nav } from '../browser-pages/browser-components/Nav';
import { SideNav } from '../browser-pages/browser-components/SideNav';
import { getCommentsCount, threedigitized, loadFilters, EditTicketEvent } from './ticketHelper';
import { toast } from 'react-toastify';
import { EditTicketSkeleton } from '../shared/components/skeletonloaders/EditTicketSkeleton';
import { FILTERS_STRINGS, LICENSE_FEATURE, PRODUCT_LICENSE, STATUS_GUID, TICKET_PAGINATION, toastDefault } from '../shared/utils/constants';
import { getUser } from '../AuthConfig';
import { BreadCrumbItemProps, EditTicketToolbar } from '../shared/components/EditTicketToolbar';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import { EditTicketsView } from '../shared/components/EditTicketsView/EditTicketsView';
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import { TicketConversation } from '../shared/components/TicketConversation/TicketConversation';
import { TicketDetailCard } from './EditTicket/TicketDetailCard';
import { TopBarSkeleton } from '../shared/components/skeletonloaders/TopBarSkeleton';
import { EditTicketFormSkeleton } from '../shared/components/skeletonloaders/EditTicketFormSkeleton';
import { EditTicketForm } from '../shared/components/EditTicketForm';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { getAllMergedTicketIds, IRelatedTickets } from '../shared/components/EditTicketRelateTickets/RelateTicketHelper';
import { graphService } from '../shared/services/graph.service';
import { AccessDenied } from '../shared/components/AccessDenied';
import { useTicketsPQ } from '../shared/hooks/useTicketsPQ';
import { getViewIdFromStorage } from './toolbar/CustomViewPopup/heplers/customPopupHelper';
import { Route } from '../../route-constants';
import { TicketLifecycleSection } from '../shared/components/TicketLifecycleTab/TicketLifecycleSection';
import { getStatusIdByGuidHelper } from '../shared/utils/helper';
import { CheckLogin } from '../shared/components/CheckLogin';
import { useTranslation } from 'react-i18next';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { FileProps } from '../shared/components/EditTicketAttachments/EditTicketAttachments';
import { retrieveTicketInformation } from '../shared/utils/propertyHelper';

const ACTIONS = {
  CALL_API: 'call-api',
  SUCCESS: 'success',
  ERROR: 'error',
  SAVING_STATE: 'saving-state',
  SAVING_SUCCESS: 'saving-success',
  SET_TICKET: 'set-ticket',
  SET_TICKETS: 'set-tickets',
  UPDATE_TICKET: 'update-ticket',
  SET_SIGNEDIN_USER: 'set-signedin-user',
  IS_GETTING_USER: 'is-getting-user',
  IS_GETTING_REQUESTER: 'is-getting-requester',
  SET_REQUESTER: 'set-requester',
  SET_ADMIN: 'set-admin',
  AUTHENTICATE_SUCCESS: 'authenticate-success',
  AUTHENTICATE_LOADING: 'authenticate-loading',
  NO_ACCESS: 'no_access',
  TAKING_TICKET: 'taking_ticket',
  TAKING_TICKET_SUCCESS: 'taking-ticket-success',
  UNASSIGNING: 'unassigning',
  UNASSIGNING_SUCCESS: 'unassigning-success',
  EDITTING: 'editting',
  DONE_EDITTING: 'done-editting',
  APPLY_TEMPLATE: 'apply-template',
  APPLY_TEMPLATE_SUCCESS: 'apply-template-success',
  SET_FILE_CHANNEL: 'set-file-channel',
  SET_TICKET_SLAS: 'set-ticket-SLAs',
  APPLY_LIFECYCLE: 'apply-lifecycle',
  APPLY_LIFECYCLE_SUCCESS: 'apply-lifecycle-success',
  APPLY_LIFECYCLE_TICKET: 'apply-lifecycle-ticket',
  RESET_UPDATE_TICKET: 'reset-update-ticket'
};

const editPageReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SAVING_STATE: {
      return {
        ...state,
        isSavingState: true
      };
    }
    case ACTIONS.SAVING_SUCCESS: {
      return {
        ...state,
        isSavingState: false
      };
    }
    case ACTIONS.SET_TICKETS: {
      return {
        ...state,
        tickets: action.data
      };
    }
    case ACTIONS.UPDATE_TICKET:
    case ACTIONS.SET_TICKET: {
      return {
        ...state,
        ticket: action.data
      };
    }
    case ACTIONS.CALL_API: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ACTIONS.SET_SIGNEDIN_USER: {
      return {
        ...state,
        signedInUser: action.data,
        isGettingSignedInUser: false
      };
    }
    case ACTIONS.IS_GETTING_USER: {
      return {
        ...state,
        isGettingSignedInUser: true
      };
    }
    case ACTIONS.SET_REQUESTER: {
      return {
        ...state,
        requester: action.data,
        isGettingRequester: false
      };
    }
    case ACTIONS.SET_ADMIN: {
      return {
        ...state,
        isAdminOrAnalyst: true
      };
    }
    case ACTIONS.NO_ACCESS: {
      return {
        ...state,
        hasAccess: false
      };
    }
    case ACTIONS.TAKING_TICKET: {
      return {
        ...state,
        isTakingTicket: true
      };
    }
    case ACTIONS.TAKING_TICKET_SUCCESS: {
      return {
        ...state,
        isTakingTicket: false
      };
    }
    case ACTIONS.UNASSIGNING: {
      return {
        ...state,
        isUnassigning: true
      };
    }
    case ACTIONS.UNASSIGNING_SUCCESS: {
      return {
        ...state,
        isUnassigning: false
      };
    }
    case ACTIONS.EDITTING: {
      return {
        ...state,
        isEditting: true
      };
    }
    case ACTIONS.DONE_EDITTING: {
      return {
        ...state,
        isEditting: false
      };
    }
    case ACTIONS.APPLY_TEMPLATE: {
      return {
        ...state,
        isApplyTemplateOpen: true
      };
    }
    case ACTIONS.APPLY_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isApplyTemplateOpen: false
      };
    }
    case ACTIONS.SET_FILE_CHANNEL: {
      return {
        ...state,
        fileTriggerChannel: action.data
      };
    }
    case ACTIONS.SET_TICKET_SLAS: {
      return {
        ...state,
        ticket: { ...state.ticket, TicketSlas: action.data }
      };
    }
    case ACTIONS.APPLY_LIFECYCLE: {
      return {
        ...state,
        isApplyLifecycleOpen: true
      };
    }
    case ACTIONS.APPLY_LIFECYCLE_SUCCESS: {
      return {
        ...state,
        isApplyLifecycleOpen: false
      };
    }
    case ACTIONS.RESET_UPDATE_TICKET: {
      return {
        ...state,
        resetUpdateTicketState: action.data
      };
    }
    case ACTIONS.APPLY_LIFECYCLE_TICKET: {
      return {
        ...state,
        ticket: { ...state.ticket, TicketLifecycle: action.data, TicketLifecycleId: action.data["Id"] }
      };
    }
    default:
      throw new Error('Unexpected action');
  }
};

const editPageInitialState = {
  hasAccess: true,
  isSavingState: false,
  signedInUser: {},
  ticket: {},
  isLoading: true,
  isGettingSignedInUser: true,
  isGettingRequester: true,
  requester: {},
  isAdminOrAnalyst: false,
  isTakingTicket: false,
  isUnassigning: false,
  isEditting: false,
  isApplyTemplateOpen: false,
  isApplyLifecycleOpen: false,
  fileTriggerChannel: '',
  resetUpdateTicketState: false
};

interface IMenuProps {
  menu: number;
  menuItems: any[];
  menuIndexChange: (_e: any, props: any) => void;
}

export const SingleTicketView: React.FC = () => {
  const { t } = useTranslation();
  const api = new platformService();
  const graphAPI = new graphService();
  let FAILEDAPICOUNT = 0;
  const [isInTeam, setIsInTeam] = useState(checkInTeams() ? 'height--50' : `height--100`);
  const [state, dispatch] = useReducer(editPageReducer, editPageInitialState);
  const history = useHistory();
  const [menu, setMenu] = useState(0);
  const [reloadConversation, setReloadConversation] = useState<boolean>(false);
  const setAppState = useSetState();
  const currentState = appState();
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const [isLifecycleEnabled, setIsLifecycleEnabled] = useState<boolean>(false);

  const [activeDrodpdownIndex, setActiveDrodpdownIndex] = useState(0);
  const [mergedTicketIds, setMergedTicketIds] = useState<number[]>([]);
  const [commentsCount, setCommentsCount] = useState<number>(0);

  const { ticketId } = useParams<any>();
  const [ticketState, setTicketState] = useState({ ticketId: ticketId, isSelectItem: false, isTicketLoaded: false });
  const [selectedTicket, setSelectedTicket] = useState({ ticketId: ticketId, reload: false });
  const [isAuthorizedAccess, setIsAuthorizedAccess] = useState(true);
  const [sideViewTicketsState, setSideViewTicketsState] = useState<SideViewTicketState>({
    loading: false,
    hasMore: false,
    pageNumber: 1,
    tickets: [],
    totalTickets: 0,
    searchText: ''
  });
  const { getParentFiltersQuery } = useTicketsPQ();
  const [detailCardApprovalsList, SetDetailCardApprovalsList] = useState<TicketApprovals[]>([])
  const [detailCardTaskList, SetDetailCardTaskList] = useState<TicketTask[]>([]);
  const [droppedIndex, setDroppedIndex] = useState<number>(1); 

  // Functions
  const getFilesChannel = async () => {
    try {
      const { data, status } = await api.getTeamsBotConfiguration();
      if (status === 200) dispatch({ type: ACTIONS.SET_FILE_CHANNEL, data: data });
    } catch (e) {
      console.error(e);
    }
  };

  const loadFiltersData = async () => {
    try {
      const newState = await loadFilters(api, graphAPI, currentState, setAppState);
      FAILEDAPICOUNT = 0;
      return newState;
    } catch (error) {
      if (++FAILEDAPICOUNT <= 5) loadFiltersData();
      console.error(error);
    }
  };

  const getPopulatedTicket = async(ticketId: number, filter: string): Promise<Ticket> => {
    const { data } = await api.getTicket(ticketId, filter);
    if (data) {
      const index = await getLatestDroppedIndex(data.Id);
      setDroppedIndex(index);
    }

    return await retrieveTicketInformation(data, currentState);
    //FileAttachments,Comments,Template
  }

  const loadPage = async () => {
    try {
      const isRelateTicketEnabled = await getCachedFeature(LICENSE_FEATURE.RelatedTickets);
      const results = await Promise.all([
        (currentState?.ticketStatus?.length > 0) ? Promise.resolve(currentState) : loadFiltersData(),
        getPopulatedTicket(ticketState.ticketId, FILTERS_STRINGS.TICKET_DETAILS),
        isRelateTicketEnabled ? api.getAllMergedTickets() : Promise.resolve([]),
        getCachedFeature(PRODUCT_LICENSE.AnalystAccess),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle),
        api.getTeamsBotConfiguration(),
        api.getTicketCommentCount(ticketState.ticketId)
      ]);

      setAppState(prevState => ({ ...prevState, userImages: {},currentUserEmail : currentState.userRoles.roles[0], teamsConfig: results[5].data }));

      setIsUserLicensed(results[3]);
      setIsLifecycleEnabled(results[4]);

      const requester = results[0].platformusers.find(u => u.Id === results[1].RequesterId);
      const me = results[0].platformusers.find(u => u.UserName.toLowerCase() === getUser().toLowerCase());

      dispatch({ type: ACTIONS.SET_TICKET, data: results[1] });
      dispatch({ type: ACTIONS.SET_SIGNEDIN_USER, data: me });
      dispatch({ type: ACTIONS.SET_REQUESTER, data: requester });
      dispatch({ type: ACTIONS.SUCCESS });
      setIsAuthorizedAccess(true)

      if (ticketState.isSelectItem) {
        setTicketState({ ...ticketState, isSelectItem: false, isTicketLoaded: true });
      }

      const mergedTicketIdsList = isRelateTicketEnabled ? getAllMergedTicketIds(ticketState.ticketId, results[2]) : [];
      setMergedTicketIds(mergedTicketIdsList);
      if (mergedTicketIdsList && mergedTicketIdsList.length > 0) setTicketCommentsCount(mergedTicketIdsList);
      else setCommentsCount(results[6] ?? 0);

    } catch (e) {
      const errCode = e['response']?.status;
      if (errCode == 404) setIsAuthorizedAccess(false);
      console.error(e);
    }
  };

  const fixHeight = () => {
    setIsInTeam(checkInTeams() ? 'height--100' : 'height--150');
  };
  const resetCommentsCount = () => {
    setTicketCommentsCount(mergedTicketIds);
  }

  const setTicketCommentsCount = async (mergedTicketIdsList: number[] = []) => {
    const ticketIds = [...mergedTicketIdsList, parseInt(ticketState.ticketId.toString())].join(',');
    const result = await api.getTicketComments(ticketIds);
    setCommentsCount(getCommentsCount(result));
  }

  const breadcrumbItems = (): BreadCrumbItemProps[] => {
    const state: any = history.location.state;

    const defaultItems: BreadCrumbItemProps[] = [
      { title: 'Tickets', link: '/' }
    ];

    if (state !== undefined) {
      const localCustomViewId = getViewIdFromStorage('custom');
      const queryParam = localCustomViewId ? `?id=${localCustomViewId}` : '';

      switch (state.from) {
        case 'mywork':
          return [{ title: 'My Work', link: Route.myWork.pagePath }];
        case 'mygroupwork':
          return [{ title: 'My Group Work', link: Route.myGroupWork.pagePath }];
        case 'board':
          return [{ title: 'Tickets', link: `/tickets/board${queryParam}` }];
        case 'list':
          return [{ title: 'Tickets', link: `/tickets/list${queryParam}` }];
        default:
          return defaultItems;
      }
    }

    return defaultItems;
  };

  const onReloadPhases = async () => {
    const data = await getPopulatedTicket(ticketId, FILTERS_STRINGS.TICKET_DETAILS);
    dispatch({ type: ACTIONS.SET_TICKET, data });
  }

  const onToolbarInteraction = async action => {
    if (action.event === 'saveTicket') dispatch({ type: ACTIONS.SAVING_STATE });
    if (action.event === 'takeTicket') {
      dispatch({ type: ACTIONS.TAKING_TICKET });
      dispatch({ type: ACTIONS.SET_TICKET, data: action.data });
    }
    if (action.event === 'onUnassign') {
      dispatch({ type: ACTIONS.UNASSIGNING });
      dispatch({ type: ACTIONS.SET_TICKET, data: action.data });
    }
    if (action.event === 'onEdit') dispatch({ type: ACTIONS.EDITTING });
    if (action.event === 'onApplyTemplate')
      dispatch({ type: ACTIONS.APPLY_TEMPLATE });
    if (action.event === 'onApplyLifecycle')
      dispatch({ type: ACTIONS.APPLY_LIFECYCLE });
    if (action.event === 'set-ticket-SLAs') {
      dispatch({
        type: ACTIONS.SET_TICKET_SLAS,
        data: action.data
      });
    }
  };

  const saveComplete = async action => {
    if (action.event === EditTicketEvent.SaveSuccess || action.event === EditTicketEvent.DeleteSuccess) {
      (async () => {
        const data = await getPopulatedTicket(ticketId, FILTERS_STRINGS.TICKET_DETAILS);
        filterSideViewTicketsOnTicketSelection(data);
        dispatch({ type: ACTIONS.SET_TICKET, data });
      })();

      dispatch({ type: ACTIONS.SAVING_SUCCESS });
      toast.success(action.event === EditTicketEvent.DeleteSuccess ? t('ticket-details.edit-ticket-view.file-deleted') : t('ticket-details.edit-ticket-view.ticket-updated-successfully', { ticketId: ticketId }), toastDefault);
      dispatch({ type: ACTIONS.DONE_EDITTING });
    }
    if (action.event === EditTicketEvent.TicketUnassigned) {
      dispatch({ type: ACTIONS.UNASSIGNING_SUCCESS });
      toast.success(t('ticket-details.edit-ticket-view.ticket-updated-successfully', { ticketId: ticketId }), toastDefault);
    }
    if (action.event === EditTicketEvent.TicketTaken) {
      dispatch({ type: ACTIONS.TAKING_TICKET_SUCCESS });
      toast.success(t('ticket-details.edit-ticket-view.ticket-updated-successfully', { ticketId: ticketId }), toastDefault);
    }
    if (action.event === EditTicketEvent.TemplateApplied) {
      dispatch({ type: ACTIONS.APPLY_TEMPLATE_SUCCESS });

      if (action.event === 'onApplyLifecycleSuccess')
        dispatch({ type: ACTIONS.APPLY_LIFECYCLE_SUCCESS });

      if (action.event === 'onApplyLifecycleToTicket')
        dispatch({ type: ACTIONS.APPLY_LIFECYCLE_TICKET, data: action.data });
    }
    
    if (action.event === 'onAttachmentsUpdate'){
      setReloadConversation(true);
    }
    if (action.event === EditTicketEvent.TicketsRelated) {
      const mergedTicketIdsList = getAllMergedTicketIds(ticketState.ticketId, (action.data as IRelatedTickets[]));
      setMergedTicketIds(mergedTicketIdsList);
      const results = await Promise.all([
        getPopulatedTicket(ticketId, FILTERS_STRINGS.TICKET_DETAILS),
        setTicketCommentsCount(mergedTicketIdsList)
      ]);
      if (results[0]) {
        dispatch({ type: ACTIONS.SET_TICKET, data: { ...state.ticket, ...results[0] } });
      }
    }
  };

  // Menu Items
  const itemRenderer = (MenuItem, props) => {
    const { tooltip = '', key, ...rest } = props;
    if (key === 'template' && (state.ticket.TemplateId || 0) <= 0)
      rest.disabled = true;

    return (
      <Tooltip content={tooltip} key={key}>
        <MenuItem {...rest} />
      </Tooltip>
    );
  };

  let menuItems = [
    {
      key: 'activity',
      content: t('ticket-details.menu.activity'),
      tooltip: t('ticket-details.menu.tooltip'),
      children: itemRenderer
    }
  ];

  if (state.ticket.TicketLifecycleId && isLifecycleEnabled) {
    menuItems.push(
      {
        key: 'lifecycle',
        content: t('common.lifecycle-with-space'),
        tooltip: t('common.show-lifecycle'),
        children: itemRenderer
      }
    )
  }

  const menuIndexChange = (_e: any, props: any) => {
    setMenu(props.activeIndex);
    const lifecycleMenuIndex = menuItems.findIndex(x => x.key == "lifecycle");
    if (props.activeIndex != lifecycleMenuIndex)
      setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: false, lifecyclePhaseMenu: undefined }));
    else {
      setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: true }));
      onReloadPhases();
    }
  };

  const filterSideViewTicketsOnTicketSelection = (data) => {
    const closedStatusId = getStatusIdByGuidHelper(currentState.ticketStatus, STATUS_GUID.CLOSED);
    const resolvedStatusId = getStatusIdByGuidHelper(currentState.ticketStatus, STATUS_GUID.RESOLVED);

    setSideViewTicketsState(pre => {
      let newTickets = [...pre.tickets.map(ticket => ticket.Id == ticketId ? { ...ticket, ...data } : ticket)];

      if (activeDrodpdownIndex === 0 || activeDrodpdownIndex === 1 || activeDrodpdownIndex === 2) { // All Active, My Work, Unassigned - should not include closed and resolved tickets
        newTickets = newTickets?.filter(t => t.StatusId !== closedStatusId && t.StatusId !== resolvedStatusId)
      }
      else if (activeDrodpdownIndex === 3) { // Closed - should only include closed and resolved tickets
        newTickets = newTickets?.filter(t => t.StatusId === closedStatusId || t.StatusId === resolvedStatusId)
      }
      else if (activeDrodpdownIndex === 4) { // Deflected - should only include deflected
        newTickets = newTickets?.filter(t => t.StatusId !== getStatusIdByGuidHelper(currentState.ticketStatus, STATUS_GUID.DEFLECTED))
      }
      return { ...pre, tickets: newTickets }
    });
  }

  const loadSideViewTickets = async (_page: number, _query: string) => {
    setSideViewTicketsState(pre => ({ ...pre, loading: true }));
    try {
      const { data } = await api.getTicketList(`?$filter=${_query}&$orderby=CreatedDate desc&$top=${TICKET_PAGINATION.ITEM_PER_PAGE}&$count=true&$skip=${(_page - 1) * TICKET_PAGINATION.ITEM_PER_PAGE}&$expand=TicketSlas($orderby=CreatedDate desc;$select=CreatedDate,ModifiedDate;$expand=SlaState,ServiceLevelAgreement($select=Name,IsActive,CreatedDate;$expand=SlaCriterias($select=Id,TimeDigitPart,TimeUnitPart,SlaActionTypeId,CreatedDate)))`);
      let currrentTickets = [];
      setSideViewTicketsState(pre => {
        currrentTickets = [...pre.tickets];
        return { ...pre, tickets: currrentTickets }
      })

      const selectedTicket = data.value.filter(x => x.Id == ticketId);
      if (currrentTickets.length <= 0) {
        if (selectedTicket.length <= 0) {
          const selectedQuery = `(${_query}) and Id eq ${ticketId}`;
          let currentTicket = await api.getTicketList(`?$filter=${selectedQuery}&$orderby=CreatedDate desc&$top=1&$count=true&$expand=TicketSlas($orderby=CreatedDate desc;$select=CreatedDate,ModifiedDate;$expand=SlaState,ServiceLevelAgreement($select=Name,IsActive,CreatedDate;$expand=SlaCriterias($select=Id,TimeDigitPart,TimeUnitPart,SlaActionTypeId,CreatedDate)))`);
          if ((currentTicket?.data?.value?.length ?? 0) > 0)
            data.value.unshift(currentTicket.data.value[0]);
        }
      }
      else if (selectedTicket.length > 0) {
        data.value.splice(data.value.findIndex(x => x.Id == ticketId), 1);
      }

      setSideViewTicketsState(pre => ({
        ...pre,
        loading: false,
        hasMore: data.value.length > 0,
        tickets: [...pre.tickets, ...data.value],
        totalTickets: data['@odata.count'],
      }));

    } catch (error) {
      console.error(error);
      setSideViewTicketsState(pre => ({ ...pre, loading: false }));
    }
  };

  useEffect(() => {
    if (currentState.ticketStatus === undefined || currentState.ticketStatus.length <= 0) {
      return;
    }
    loadSideViewTickets(sideViewTicketsState.pageNumber, getParentFiltersQuery(activeDrodpdownIndex, sideViewTicketsState.searchText));
  }, [sideViewTicketsState.pageNumber, activeDrodpdownIndex, sideViewTicketsState.searchText, currentState.ticketStatus]);

  // Effects
  useEffect(() => {
    if (!ticketState.isTicketLoaded) {
      loadPage();
      if (state?.fileTriggerChannel?.TriageChannel == null) getFilesChannel();
    }
  }, [ticketState]);

  useEffect(() => {
    if (selectedTicket.reload) {
      setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: false }));
      window.history.replaceState({}, 'tickets', `${window.location.origin}/tickets/${selectedTicket.ticketId}`);
      setTicketState({ ticketId: selectedTicket.ticketId, isSelectItem: true, isTicketLoaded: false });
      setSelectedTicket({ ...selectedTicket, reload: false });
      setCommentsCount(0);
      setMenu(0);
      SetDetailCardApprovalsList([]);
      SetDetailCardTaskList([]);
    }
  }, [ticketId]);

  useEffect(() => {
    if (isLifecycleEnabled) {
      const lifecycleMenuIndex = menuItems.findIndex(x => x.key == "lifecycle");
      if ((currentState?.lifecycleMenuOpen ?? false)) {
        const currentPhaseOrder = state.ticket["TicketLifecycle"]["Phases"]?.find((o: any) => o.IsCurrent)?.Order ?? 0
        const phaseOrder = currentState.lifecyclePhaseMenu ? currentState.lifecyclePhaseMenu : currentPhaseOrder;
        setAppState(prevState => ({ ...prevState, lifecyclePhaseMenu: phaseOrder }));
        if (menu != lifecycleMenuIndex) setMenu(lifecycleMenuIndex);
      }
    }
  }, [currentState.lifecycleMenuOpen]);

  useEffect(() => {
    if (isLifecycleEnabled && state.ticket.TicketLifecycleId && currentState.isRouteFromMyWork) {
      setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: true, isRouteFromMyWork: false }));
    }
  }, [isLifecycleEnabled, state.ticket.TicketLifecycleId]);

  useEffect(() => {
    return () => {
      setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: false, lifecyclePhaseMenu: undefined }));
    }
  }, [])

  const onSendComment = async (date: Date) => {
    const updatedTicket = { ...state.ticket, ModifiedDate: date.toISOString() }
    dispatch({ type: ACTIONS.SET_TICKET, data: updatedTicket });
  }

  const getLatestDroppedIndex = async (id: number): Promise<number> => {
    try {
      const response: any = await api.getTicketFiles(id);
      let idx = 0;
  
      for (const file of response) {
        if (file.FileName.indexOf('image-') > -1) {
          const indexNumber = parseInt(file.FileName.substring(file.FileName.indexOf('image-') + 'image-'.length, file.FileName.indexOf('image-') + 'image-'.length + 3));
          if (indexNumber > idx)
            idx = indexNumber;
        }
      }

      return ++idx;

    } catch {
      return 1; 
    }
  };

  const onTicketUpdate = (data: any, date: Date) => {
    const updatedTicket = {
      ...state.ticket, ...data,
      Assignee: currentState.platformusers.find(user => user.Id === data.AssigneeId),
      Status: currentState.ticketStatus.find(status => status.Id === data.StatusId),
      Priority: currentState.priority.find(priority => priority.Id === data.PriorityId),
      TicketSlas: data?.slaList ?? state.ticket.TicketSlas,
      ModifiedDate: date.toISOString()
    }
    dispatch({ type: ACTIONS.SET_TICKET, data: updatedTicket });
    filterSideViewTicketsOnTicketSelection(updatedTicket);
    toast.success(t('ticket-details.edit-ticket-view.ticket-updated-successfully', { ticketId: threedigitized(updatedTicket.Id) }), toastDefault);
    dispatch({ type: ACTIONS.RESET_UPDATE_TICKET, data: true });
  }
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);

  let edittingFormClass = state.isEditting ? `editting-state-form` : ``
  return (
    <CheckLogin onBillingIssue={fixHeight}>
      <Helmet>
        <title>{t('tab-title.ticket')} #{threedigitized(ticketState.ticketId)}{((state?.ticket?.Title || '') != '' ? `: ${state.ticket.Title}` : '')} - {t('tab-title.postfix')}</title>
      </Helmet>
      <FluentUIThemeConsumer
        render={globalTheme => (
          <div className={`h-screen`}>
            {state.isLoading && isAuthorizedAccess && !ticketState.isSelectItem && (
              <EditTicketSkeleton />
            )}
            {(!state.isLoading || ticketState.isSelectItem) && (
              <>
                <ToolbarTheme globalTheme={globalTheme}>
                  <>
                    {!state.isGettingSignedInUser && (
                      <EditTicketToolbar
                        ticketData={state.ticket}
                        ticketId={ticketState.ticketId}
                        currentUserId={state.signedInUser.Id}
                        onInteraction={onToolbarInteraction}
                        disabledSave={state.isSavingState}
                        edittingState={state.isEditting}
                        breadcrumbItems={breadcrumbItems()}
                        isItemSelected={ticketState.isSelectItem}
                        isUserLicensed={isUserLicensed}
                        mergedTicketIds={mergedTicketIds}
                      />
                    )}
                    {state.isGettingSignedInUser && (
                      <EditTicketToolbar
                        ticketData={state.ticket}
                        ticketId={ticketState.ticketId}
                        currentUserId={null}
                        disabledSave={state.isSavingState}
                        edittingState={state.isEditting}
                        breadcrumbItems={breadcrumbItems()}
                        isItemSelected={ticketState.isSelectItem}
                        isUserLicensed={isUserLicensed}
                        mergedTicketIds={mergedTicketIds}
                      />
                    )}
                  </>
                </ToolbarTheme>
                <>
                  <Flex
                    className={`h-full ${AppCSS.tikitEditBody} overflow-hidden relative`}
                    style={{ height: 'calc(100% - 50px) !important', top: '-18px', ...ThemeColorScheme(globalTheme.siteVariables) }}
                  >
                    <Flex.Item>
                      <EditTicketsView
                        selectedTicketId={ticketState.ticketId}
                        onTicketClick={(selectedticketId: number) => {
                          setSelectedTicket({ ticketId: selectedticketId, reload: true });
                        }}
                        activeDrodpdownIndex={activeDrodpdownIndex}
                        setActiveDrodpdownIndex={setActiveDrodpdownIndex}
                        ticketsState={sideViewTicketsState}
                        setTicketsStateHandler={setSideViewTicketsState}
                      />
                    </Flex.Item>
                    {!ticketState.isSelectItem ? (
                      <Flex.Item styles={{ flex: '1' }}>
                        <Flex column className={`w-0 pt-5 px-5 relative flex flex-col edit-page justify-between ${isInTeam}`}>
                          {!state.isLoading && (
                            <TicketDetailCard
                              commentsCount={commentsCount}
                              attachmentsCount={currentState.uploadingState.uploadedFiles.length}
                              ticketData={state.ticket}
                              showViewMore
                              detailCardApprovalsList={detailCardApprovalsList}
                              detailCardTaskList={detailCardTaskList}
                            />
                          )}
                          <Flex.Item key={menu}>
                            <ContentMenu menu={menu} menuItems={menuItems} menuIndexChange={menuIndexChange} />
                          </Flex.Item>
                          {menu === 0 &&
                            !state.isGettingSignedInUser &&
                            !state.isGettingRequester && (
                              <TicketConversation
                                isDropzoneDisabled={
                                  state?.fileTriggerChannel?.TriageChannel == null ||
                                    state?.fileTriggerChannel?.TriageChannel?.length < 1 ||
                                    state?.ticket == null || state?.ticket?.Closed
                                }
                                status={state.ticket.StatusId}
                                dueDate={state.ticket.DueDate}
                                priority={state.ticket.PriorityId}
                                teamId={state.ticket.TeamId}
                                groupId={state.ticket.SupportGroupId}
                                isTicketMerged={state.ticket.IsMerged}
                                isTicketClosed={state.ticket.Closed}
                                isUserLicensed={isUserLicensed}
                                ticketId={ticketState.ticketId}
                                assigneeId={state.ticket.AssigneeId}
                                requester={state.requester}
                                signedInUser={state.signedInUser}
                                isEndUserPage={false}
                                onTicketUpdate={onTicketUpdate}
                                resetCommentsCount={resetCommentsCount}
                                onSendComment={onSendComment}
                                ticketData={state.ticket}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                reloadConversation={reloadConversation}
                                setReloadConversation={setReloadConversation}
                                globalTheme={globalTheme}      
                                droppedIndex={droppedIndex}
                              />
                            )}
                          {menu === 1 &&
                            !state.isGettingSignedInUser &&
                            !state.isGettingRequester && (
                              <TicketLifecycleSection
                                ticketData={state.ticket}
                                ticketLifecycle={state.ticket.TicketLifecycle}
                                data={currentState}
                                onReloadPhases={onReloadPhases}
                              />
                            )}
                        </Flex>
                      </Flex.Item>
                    ) : (
                      <Flex.Item styles={{ height: 'calc(100vh - 50px)', flex: '1' }}>
                        <Flex column className={`pt-5 px-5 relative flex flex-col`}>
                          <Flex.Item>
                            <TopBarSkeleton />
                          </Flex.Item>
                        </Flex>
                      </Flex.Item>
                    )}
                    {!ticketState.isSelectItem ? (
                      <Flex.Item
                        className={`collapsible-component ${edittingFormClass} w-1/4 height--50 overflow-auto pb-10`}
                        styles={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                      >
                        <div className={`p-5 m-0 overflow-auto mobile-editform`} >
                          <div className={`block md:hidden mb-3`}>
                            <Button
                              onClick={() => { dispatch({ type: ACTIONS.DONE_EDITTING }); }}
                              className={`mb-1`}
                              content={<ArrowLeftIcon size="large" />}
                              text
                              iconOnly
                              style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}
                            />
                          </div>
                          <EditTicketForm
                            globalTheme={globalTheme}
                            ticket={state.ticket}
                            isUserLicensed={isUserLicensed}
                            dispatchParentEvt={dispatch}
                            unassigningState={state.isUnassigning}
                            takingState={state.isTakingTicket}
                            applyTemplateState={state.isApplyTemplateOpen}
                            applyLifecycleState={state.isApplyLifecycleOpen}
                            savingState={state.isSavingState}
                            signedInUser={state.signedInUser}
                            onInteraction={saveComplete}
                            onToolbarInteraction={onToolbarInteraction}
                            isDropzoneDisabled={
                              state?.fileTriggerChannel?.TriageChannel == null ||
                                state?.fileTriggerChannel?.TriageChannel?.length < 1 ||
                                state?.ticket == null || state?.ticket?.Closed
                            }
                            fileTriggerChannel={state?.fileTriggerChannel?.TriageChannel}
                            setTicketsStateHandler={setSideViewTicketsState}
                            SetdetailCardApprovalsList={SetDetailCardApprovalsList}
                            setDetailCardTaskList={SetDetailCardTaskList}
                            resetUpdateTicketState={state.resetUpdateTicketState} 
                          />
                        </div>
                      </Flex.Item>
                    ) : (
                      <Flex.Item className={`hidden md:block`} styles={{ width: '25%', backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}>
                        <div className={`p-5 m-0 overflow-auto`}>
                          <EditTicketFormSkeleton />
                        </div>
                      </Flex.Item>
                    )}
                  </Flex>
                </>
              </>
            )}
            {!isAuthorizedAccess && (
              <AccessDenied />
            )}
          </div>
        )}
      />
    </CheckLogin>
  );
};

const ContentMenu = ({ menu, menuItems, menuIndexChange }: IMenuProps) => (
  <div style={{ marginTop: '0.5rem' }}>
    <Menu
      defaultActiveIndex={menu}
      items={menuItems}
      underlined
      primary
      onActiveIndexChange={menuIndexChange}
    />
  </div>
);

export const EditTicket: React.FC = () => {
  const setState = useSetState();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setState(state => {
      let newstate = { ...state, isLoading: true };
      return newstate;
    });
  }, []);
  let state: any = history.location.state || { from: 'tickets' };
  const active = state.from === '' ? 'tickets' : state.from;

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(true);
  (async () => setIsFeatureEnabled((await getCachedFeature(LICENSE_FEATURE.TicketPortal))))();

  return isFeatureEnabled ? (
    <>
      {checkInTeams() && <SingleTicketView />}
      {!checkInTeams() && (
        <>
          <Nav />
          <div className={'w-full'}>
            <div className={'flex'}>
              <div className="hidden md:flex">
                <SideNav
                  active={active}
                  hideSettingsNav={true}
                />
              </div>
              <div className={'w-full flex-1'}>
                <SingleTicketView />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  ) : <UpgradeNeeded
    toolbarText={t('ticket.toolbar.tickets')}
    headerText={t('ticket-portal.no-access-current-plan')}
    subheaderText={t('ticket-portal.no-access-upgrade-plan')} />
};

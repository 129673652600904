import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  SvgIconSizeValue,
  Grid,
  gridHorizontalBehavior,
  SpeakerPersonIcon,
  ProviderConsumer as FluentUIThemeConsumer,
  CalendarIcon,
  TeamCreateIcon,
  Divider
} from '@fluentui/react-northstar';
import GroupIcon from '../../../../svg/group.svg';
import TicketSVG from '../../../../svg/ticket.svg';
import CategoryIcon from '../../../../svg/category.svg';
import AttachmentIcon from '../../../../svg/attachment.svg';
import PriorityIcon from '../../../../svg/priority-icon.svg';
import TicketTypeIcon from '../../../../svg/ticket-type-filled.svg';
import EmailIcon from '../../../../svg/email.svg';
import TemplateIcon from '../../../../svg/template.svg';
import TagsIcon from '../../../../svg/tags-icon.svg';
import CollaboratorIcon from '../../../../svg/collaboratorIcon.svg';
import { TicketStatus } from '../TicketCardTemplate';
import AppCSS from '../../../../../src/components/App.module.css';
import Styles from './TicketDetailInfo.module.css';
import { DateTime } from 'luxon';
import { threedigitized } from '../../../tikit/ticketHelper';
import { toTitleCase } from '../../utils/helper';
import { LICENSE_FEATURE, TICKET_DETAIL } from '../../utils/constants';
import { UserAvatar } from '../UserAvatar';
import { Title } from './Title';
import { CheckFeature } from '../CheckFeature';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { appState, useSetState } from '../../../AppState';
import _ from 'lodash';
import { SlaPills } from '../SlaPills/SlaPills';
import { PeopleIcon } from '@fluentui/react-icons-mdl2';
import { TransformToLink } from '../TransformToLink/TransformToLink';
import { UsersTooltip } from './UsersTooltip';
import AffectedUserIcon from '../../../../svg/affected-user.svg'
import CommentsIcon from '../../../../svg/comments-filled-icon.svg'
import TaskSVG from '../../../../svg/task-icon.svg';
import ApprovalsSVG from '../../../../svg/approval-detail-card.svg';
import { DisplayLifecycleWithPhases } from './displayLifecyclePhases/DisplayLifecycleWithPhases';
import { useTranslation } from 'react-i18next';

const iconStyle = {
  size: 'small' as SvgIconSizeValue,
  style: { color: '#8f90a6' }
};

const showRequester = requester => {
  let name = '';
  if (requester && requester?.FullName) {
    name = requester?.FullName;
  } else {
    name = 'No Requester';
  }
  return name;
};

const setIdColor = (idColorPurple: boolean,globalTheme: any): React.CSSProperties => {
  return idColorPurple ? { color: `${globalTheme.siteVariables.colorScheme.brand.foreground}`, fontWeight: 'bold' } : {};
};

interface Props {
  attachmentsCount?: number;
  commentsCount?: number;
  isBoardMobileView?: boolean;
  idColorPurple?: boolean;
  ticketData: any;
  showViewMore?: boolean;
  showDetailPopup?: (value: boolean) => void;
  style?: React.CSSProperties;
  detailCardApprovalsList?: TicketApprovals[];
  detailCardTaskList?: TicketTask[];
}

export const showParentChild = (item: any, itemArray: any) => {
  let values = [];

  function dataManiplate(selectedItem) {
    if (!selectedItem?.ParentId) {
      values = [];
      if (selectedItem?.Value) {
        values.push(selectedItem?.Value);
      }
      return;
    }
    const parentCategoryValue = _.find(itemArray, o => o?.Id == selectedItem.ParentId);
    dataManiplate(parentCategoryValue);
    values.push(selectedItem?.Value);
  }
  dataManiplate(item);
  return values;
};

const ShowMore = ({ showViewMore, showDetailPopup, ticketData }: Props) => {
  const {t} = useTranslation();
  const _tags = ticketData?.Tags || [];
  const _tagsName = _tags.map((data: any) => data.Name).join(', ');
  return (
    <>
      {showViewMore && (
        <Grid accessibility={gridHorizontalBehavior} columns="1">
          <div className={`block md:hidden`}>
            <span className={`${AppCSS['ViewMore']} font-semibold text-sm mb-1`} onClick={() => { showDetailPopup(true); }}>
              {t('ticket-details.ticket-details-card.ticket-details-info.show-more')}
            </span>
          </div>
        </Grid>
      )}
      {!showViewMore && (
        <CheckFeature featureName={LICENSE_FEATURE.TicketTags}>
          <Grid accessibility={gridHorizontalBehavior} columns="1">
            <Flex vAlign="start" gap="gap.small" className={`${Styles['grid-item']}`}>
              <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                <TagsIcon />
                <Title text={TICKET_DETAIL.TAGS} />
              </Flex>
              <Flex>{_tags.length > 0 ? _tagsName : t('ticket-details.ticket-details-card.ticket-details-info.none')}</Flex>
            </Flex>
          </Grid>
        </CheckFeature>
      )}
    </>
  );
};

export const TicketDetailInfo = ({
  attachmentsCount,
  commentsCount,
  isBoardMobileView = false,
  idColorPurple = false,
  ticketData,
  showViewMore = false,
  showDetailPopup,
  style = {},
  detailCardApprovalsList,
  detailCardTaskList
}: Props) => {
  const { t, i18n } = useTranslation();
  const currentstate = appState();
  const setAppState = useSetState();
  const { categories, priority, ticketTypes } = currentstate;
  const [category, setCategory] = useState([]);
  const [showPriority, setShowPriority] = useState([]);
  const [type, setType] = useState([]);
  useEffect(() => {
    setCategory(showParentChild(ticketData?.Category, categories));
    setShowPriority(showParentChild(ticketData?.Priority, priority));
    setType(showParentChild(ticketData?.TicketType, ticketTypes));
  }, [ticketData]);

  const ticketSlas: TicketSla[] = ticketData['TicketSlas'] || [];
  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <div className="min-h-32" style={{  color: 'var(--mgt-theme-default-foreground)', ...ThemeColorScheme(globalTheme.siteVariables) }}>
          <Flex
            column
            gap="gap.small"
            style={{
              backgroundColor: 'var(--mgt-theme-background)',
              padding: '0.75rem',
              boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11)',
              ...style
            }}
          >
            <Flex vAlign="center" className={`leading-none`}>
              <Flex vAlign="center" className='mr-2'>
                <TicketSVG width={24} className={`mr-3`} />
                <Text style={setIdColor(idColorPurple,globalTheme)}>
                  #{threedigitized(ticketData?.Id)}
                </Text>
              </Flex>
              {/* tailwind `h-6` is not working because there's a style overriding it */}
              <Divider vertical className='mr-2' style={{height: "24px"}}/>
              
              {ticketData?.Requester != null ? (
                  <UserAvatar
                    {...{
                      idOrUpn: ticketData.Requester?.AadObjectId ?? ticketData.Requester?.UserName,
                      avatarProps: { name: ticketData.Requester?.FullName, size: 'small' }
                    }}
                  />
                ) : (
                  <SpeakerPersonIcon
                    size="small"
                    style={{ color: '#8f90a6' }}
                    variables={{ largeSize: '30px' }}
                  />
                )}
                
              <Text weight="semibold" size="medium" className='mx-2'>
                {showRequester(ticketData?.Requester)}
              </Text>              
              <Flex vAlign="center" gap="gap.smaller">
                <p style={{ fontSize: '12px' }}>
                  {t('ticket-details.ticket-details-card.ticket-details-info.created')}&nbsp;
                  {ticketData['CreatedDate'] ? DateTime.fromISO(ticketData['CreatedDate']).setLocale(i18n.language).toFormat('EEE, MMM d yyyy') : t('ticket-details.ticket-details-card.ticket-details-info.none')}
                </p>
                {ticketData?.Source?.Value == 'Email' ? (
                  <>
                    <span style={{ fontSize: '12px' }}>{t('ticket-details.ticket-details-card.ticket-details-info.via-email')}</span>
                    <span>
                      <EmailIcon width={12} height={12} stroke="#8f90a6" />{' '}
                    </span>
                  </>
                ) : ('')}
              </Flex>
              <Divider vertical  className='mx-2' style={{height: "24px"}}/>              
              <Flex vAlign="center" gap="gap.small">
                <Flex vAlign="center" gap="gap.smaller">
                  <PriorityIcon width={10} height={10} />
                </Flex>
                <div className={`w-full truncate`} dir="rtl">
                  <bdi>{showPriority.length > 0 ? showPriority.join(' > ') : t('ticket-details.ticket-details-card.ticket-details-info.none')}{' '}</bdi>
                </div>
              </Flex>
              <Divider vertical  className='mx-2' style={{height: "24px"}}/>
              <TicketStatus status={ticketData?.Status} />
            </Flex>

            <Text weight="semibold" className="">
              <TransformToLink text={ticketData.Title} />
            </Text>

            <div className={`w-full sm:text-sm`}>
              <div className={`grid grid-cols-1 md:grid-cols-3`}>
                {!showViewMore && (
                  <>
                    <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                      <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`}>
                        <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                          <TeamCreateIcon {...iconStyle}/>
                          <Title text={toTitleCase(TICKET_DETAIL.TEAM)} />
                        </Flex>
                        <Flex>
                          {ticketData?.Team ? ticketData.Team['FriendlyName'] : t('ticket-details.ticket-details-card.ticket-details-info.none')}{' '}
                        </Flex>
                      </Flex>
                    </CheckFeature>
                    <CheckFeature featureName={LICENSE_FEATURE.TicketTypes}>
                      <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`} >
                        <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                          <TicketTypeIcon
                            style={{ marginRight: '5px', fill: '#8f90a6' }}
                            width={16}
                            height={16}
                            stroke="#8f90a6"
                          />
                          <Title text={toTitleCase(TICKET_DETAIL.TICKET_TYPE)} />
                        </Flex>
                        <div className={`w-full truncate mr-4`} dir="rtl">
                          <bdi>{type.length > 0 ? type.join(' > ') : t('ticket-details.ticket-details-card.ticket-details-info.none')}{' '}</bdi>
                        </div>
                      </Flex>
                    </CheckFeature>
                    <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`}>
                      <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                        <TemplateIcon />
                        <Title text={toTitleCase(TICKET_DETAIL.TEMPLATE)} />
                      </Flex>
                      <Flex>
                        <div className={`w-full truncate mr-4`} style={{textOverflow: 'ellipsis', maxWidth: '170px'}}>
                          <bdi>{ticketData?.Template?.Name ? ticketData?.Template?.Name : t('ticket-details.ticket-details-card.ticket-details-info.none')} </bdi>
                        </div>       
                      </Flex>
                    </Flex>
                    <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`}>
                      <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                        <GroupIcon />
                        <Title text={toTitleCase(TICKET_DETAIL.GROUP)} />
                      </Flex>
                      <Flex>
                        {ticketData?.SupportGroup ? ticketData.SupportGroup['Name'] : t('ticket-details.ticket-details-card.ticket-details-info.none')}{' '}
                      </Flex>
                    </Flex>
                    <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`}>
                      <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                        <CategoryIcon width={12} height={12} stroke="#8f90a6" />
                        <Title text={toTitleCase(TICKET_DETAIL.CATEGORY)} />
                      </Flex>
                      <div className={`w-full truncate mr-4`} dir="rtl">
                        <bdi>
                          {category.length > 0 ? category.join(' > ') : t('ticket-details.ticket-details-card.ticket-details-info.none')}{' '}
                        </bdi>
                      </div>
                    </Flex>
                    <CheckFeature featureName={LICENSE_FEATURE.ServiceLevelAgreements}>
                      <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`}>
                        <SlaPills ticketSlas={ticketSlas} svgGap={false} />
                      </Flex>
                    </CheckFeature>
                    <Flex vAlign="center" gap="gap.small" className={`${Styles['grid-item']}`}>
                      <Flex vAlign="center" gap="gap.smaller" style={{minWidth: "80px"}}>
                        <PeopleIcon {...iconStyle} />
                        <Title text={toTitleCase(TICKET_DETAIL.ASSIGNEE)} />
                      </Flex>
                      <Flex>{ticketData?.Assignee?.FullName ?? t('ticket-details.ticket-details-card.ticket-details-info.none')}</Flex>
                    </Flex>
                    <ShowMore
                      showViewMore={showViewMore}
                      ticketData={ticketData}
                      showDetailPopup={showDetailPopup}
                    />                    
                  </>
                )}
              </div>
            </div>
            <div>
              <DisplayLifecycleWithPhases ticketData={ticketData} isAnalyst />
            </div>
            <div style={{ borderTop: '1px solid lightgrey' }} className="flex flex-col sm:flex-row items-start justify-between gap-1 pt-4">
              <div className="flex items-center gap-2">
                {ticketData?.DueDate  && (
                   <>
                    <Flex vAlign="center" gap="gap.smaller">
                    <CalendarIcon style={{ color: '#8f90a6' }} />
                    <Title text={toTitleCase(TICKET_DETAIL.DUE_DATE)} />
                    </Flex><Flex style={{ paddingBottom: '3px'}}> {DateTime.fromISO(ticketData.DueDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED)}</Flex>
                  </>
                )}
                <Flex vAlign="center" gap="gap.smaller">
                  <CalendarIcon style={{ color: '#8f90a6' }} />
                  <Title text={toTitleCase(TICKET_DETAIL.MODIFIED)} />
                </Flex>
                <Flex style={{ paddingBottom: '3px'}}>
                  {DateTime.fromISO(ticketData.ModifiedDate).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)}
                </Flex>
              </div>
              {!isBoardMobileView &&  
              <div className="flex items-center">
                <span className="flex items-center gap-1">
                  <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                    <Flex vAlign="center">
                      <CollaboratorIcon width={12} height={12} style={{ margin: '0 auto', color: globalTheme.siteVariables.colorScheme.default.foreground }} />
                      <UsersTooltip ticketCollaborator={ticketData?.TicketCollaborators.map(c => currentstate.platformusers.find(p => p.Id == c.PlatformUserId))} title={t('ticket.ticket-list.collaborators')} />
                    </Flex>
                  </CheckFeature>

                  <CheckFeature featureName={LICENSE_FEATURE.AffectedUsers}>
                  <Flex vAlign="center">
                    <AffectedUserIcon width={12} height={12} style={{ margin: '0 auto', color: globalTheme.siteVariables.colorScheme.default.foreground }} />
                    <UsersTooltip ticketCollaborator={ticketData?.AffectedUsers.map(c => currentstate.platformusers.find(p => p.Id == c.PlatformUserId))} title={t('ticket.ticket-list.affected-users')} />
                  </Flex>
                  </CheckFeature>

                  <Flex vAlign="center" className='gap-0.5'>
                    <AttachmentIcon width={12} height={12} style={{ margin: '0 auto', color: globalTheme.siteVariables.colorScheme.default.foreground }} />
                    <Text size="small" style={{ color: globalTheme.siteVariables.colorScheme.default.foreground, marginLeft: '4px' }}>
                      {attachmentsCount ?? 0}
                    </Text>
                  </Flex>

                  <Flex vAlign="center" className='gap-0.5'>
                    <CommentsIcon width={12} height={12} style={{ margin: '0 auto', color: globalTheme.siteVariables.colorScheme.default.foreground }} />
                    <Text size="small" style={{ color: globalTheme.siteVariables.colorScheme.default.foreground, marginLeft: '4px' }}>
                      {commentsCount ?? 0}
                    </Text>
                  </Flex>
                  <CheckFeature featureName={LICENSE_FEATURE.Approvals}>
                    <Flex vAlign="center" className='gap-0.5'>
                      <ApprovalsSVG
                        width={12}
                        height={12}
                        style={{ margin: '0 auto' }}
                        className={``}
                      />
                      <Text size="small" style={{ color: globalTheme.siteVariables.colorScheme.default.foreground, marginLeft: '4px' }}>
                      {detailCardApprovalsList?.length ?? 0}
                    </Text>
                    </Flex>
                  </CheckFeature>

                  <CheckFeature featureName={LICENSE_FEATURE.TicketTasks}>
                    <Flex vAlign="center" className='gap-0.5'>
                      <TaskSVG
                        width={12}
                        height={12}
                        style={{ margin: '0 auto' }}
                        className={``}
                      />
                      <Text size="small" style={{ color: globalTheme.siteVariables.colorScheme.default.foreground, marginLeft: '4px' }}>
                      {detailCardTaskList?.length ?? 0}
                    </Text>
                    </Flex>
                  </CheckFeature>

                </span>
              </div>}
            </div>
          </Flex>
        </div>
      )}
    />
  );
};

import React from 'react';
import {
  Text,
  ChevronEndMediumIcon,
  Button,
  CloseIcon,
  ProviderConsumer as FluentUIThemeConsumer
} from '@fluentui/react-northstar';
import { NavLink } from 'react-router-dom';
import { appState, AppStateContext } from '../../AppState';
import {
  ADMINISTRATORS,
  ANALYSTS,
  SIDENAV_ARRANGED_KEYS,
  AGENTS
} from '../utils/constants';
import { Icon } from '@fluentui/react';
import { sidenav_unarranged } from '../../browser-pages/browser-components/SideNav';
interface Props {
  active?: number;
  menuClose?: () => void;
}

export const ENDUSER_ROUTES = [
  {
    key: 'request',
    path: '/request',
    iconElement: <Icon iconName="Ticket" className={`text-lg text-black`} />,
    title: 'Request'
  }
];

export const TicketBoardMobileMenu = (props: Props) => {
  const appContextState = appState();

  const renderSideNavItems = sidenav_unarranged.slice(0, 3).map((nav, _i) => (
    <NavLink
      to={nav.path}
      className={`block cursor-pointer `}
      activeStyle={
        SIDENAV_ARRANGED_KEYS[props.active - 1]?.toLowerCase() ===
          nav.key?.toLowerCase()
          ? {
            backgroundColor: '#f5f5f5',
            fontWeight: 600
          }
          : {}
      }
      onClick={props.menuClose}
    >
      <div className={`flex justify-between m-5 mb-5 `}>
        <div className="flex items-center">
          <Text content={nav.iconElement} className={`text-black`} />
          <Text
            content={nav.title}
            className={`pl-3 truncate ${appContextState.isSidenavCollapsed ? 'hidden' : ''} text-black`}
          />
        </div>
        <div>
          <ChevronEndMediumIcon size="large" className='text-black' />
        </div>
      </div>
    </NavLink>
  ));

  return (
    <AppStateContext.Consumer>
      {_state => {
        return (
          <FluentUIThemeConsumer
            render={globalTheme => (
              <div
                onClick={props.menuClose}
                className={`bg-black bg-opacity-50 fixed top-0 w-full h-full`}
                style={{ zIndex: 1000 }}
              >
                <div
                  className={`animation--hide-and-show fixed top-0 w-3/4 bg-white h-full overflow-auto`}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  style={{ zIndex: 1100, background: globalTheme.siteVariables.colorScheme.default.background }}
                >
                  <div>
                    <Button
                      icon={<CloseIcon size="large" className={`text-black`} />}
                      text
                      iconOnly
                      title="Remove"
                      onClick={() => props.menuClose()}
                      styles={{
                        alignSelf: 'center',
                        marginLeft: '10px',
                        marginTop: '5px'
                      }}
                    />
                    <ul className={`mt-5`}>
                      {appContextState.userRoles.roles.includes(
                        ADMINISTRATORS || ANALYSTS || AGENTS
                      ) ? (
                        renderSideNavItems
                      ) : (
                        <>
                          {ENDUSER_ROUTES.map(nav => (
                            <NavLink
                              to={nav.path}
                              className={`block cursor-pointer`}
                              activeStyle={{
                                backgroundColor: '#f5f5f5',
                                fontWeight: 600
                              }}
                            >
                              <div className={`flex justify-between m-5 mb-5 `}>
                                <div className="flex items-center">
                                  {nav.iconElement}
                                  <Text
                                    content={nav.title}
                                    className={`pl-3 truncate ${appContextState.isSidenavCollapsed ? 'hidden' : ''}`}
                                  />
                                </div>
                                <div>
                                  <ChevronEndMediumIcon size="large" />
                                </div>
                              </div>
                            </NavLink>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )} />
        );
      }}
    </AppStateContext.Consumer>
  );
};
